/*
 * @Author: lanhiy lanhiy@163.com
 * @Date: 2024-06-25 16:13:00
 * @LastEditors: lanhiy lanhiy@163.com
 * @LastEditTime: 2024-10-09 18:10:02
 * @FilePath: \JiaZhen-huiYi\src\config\config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const config = {
    apiUrl: 'https://www.cqdj888.com/api/',
};

export default config;